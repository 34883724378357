/* eslint-disable indent */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector } from "./app/hooks";
import { getSessions } from "./app/slices/session";
import { getLoading } from "./app/slices/status";
import DashboardWrapper from "./components/dashboard-wrapper/dashboard-wrapper";
import Loader from "./components/loader";
import NoPermissionModal from "./components/modal/noPermissionModal";
import NoProductModal from "./components/modal/noProductModal";
import Snackbar from "./components/snackbar/snackbar";
import AuthChecker from "./utils/AuthChecker";

const Customers = React.lazy(() => import("./pages/customers/customers"));
const SingleCustomerView = React.lazy(
  () => import("./pages/customers/view-customer/singleCustomerView")
);
const Expenses = React.lazy(() => import("./pages/expenses/expenses"));
const Pos = React.lazy(() => import("./pages/pos/pos"));
const Home = React.lazy(() => import("./pages/home/home"));
const AddInventory = React.lazy(() => import("./pages/inventory/add/add"));
const Inventory = React.lazy(() => import("./pages/inventory/inventory"));
const Login = React.lazy(() => import("./pages/login/login"));
const ManageBusiness = React.lazy(() => import("./pages/manageBusinesses/index"));
const OnlinePresence = React.lazy(() => import("./pages/onlinePresence/onlinepresence"));
const Products = React.lazy(
  () => import("./pages/onlinePresence/orders/availableProducts/products")
);
const OrdersPage = React.lazy(() => import("./pages/onlinePresence/orders/orders"));
const PasswordChange = React.lazy(() => import("./pages/recover-password/passwordChange"));
const RecoverPassword = React.lazy(() => import("./pages/recover-password/recover-password"));
const CreateBusiness = React.lazy(() => import("./pages/register/create-business"));
const Register = React.lazy(() => import("./pages/register/register"));
const PageReport = React.lazy(() => import("./pages/report/report"));
const NewSales = React.lazy(() => import("./pages/sales/new-sales/new-sales"));
const Sales = React.lazy(() => import("./pages/sales/sales"));
const Settings = React.lazy(() => import("./pages/settings/settings"));
const ShopPage = React.lazy(() => import("./pages/shops"));
const CreateShop = React.lazy(() => import("./pages/shops/new/create-business"));
const Staffs = React.lazy(() => import("./pages/staffs/staffs"));
const SubscriptionDetails = React.lazy(() => import("./pages/subscriptions/subDetails"));
const Subscriptions = React.lazy(() => import("./pages/subscriptions/subscritpions"));
const SingleSupplierView = React.lazy(() => import("./pages/supply/singleSupplierView"));
const Suppliers = React.lazy(() => import("./pages/supply/supply"));
const Batches = React.lazy(() => import("./pages/inventory/product-batches/batches"));
const ProductList = React.lazy(() => import("./pages/inventory/product-list/product_list"));
const Transfer = React.lazy(() => import("./pages/inventory/transfer/prodTransfer"));
const AddNewInvoice = React.lazy(() => import("./pages/invoices/addNewInvoice"));
const Invoices = React.lazy(() => import("./pages/invoices/invoices"));
const SelectShop = React.lazy(() => import("./pages/selectShop/selectShop"));
const StockAdjustment = React.lazy(() => import("./pages/stockAdjustment/stockAdjustment"));
const AdjustStock = React.lazy(() => import("./pages/stockAdjustment/view/adjustStock"));
const AdjustmentHistory = React.lazy(
  () => import("./pages/stockAdjustment/view/adjustmentHistory")
);
const AdjustmentStockStatus = React.lazy(
  () => import("./pages/stockAdjustment/view/adjustmentStockStatus")
);

function App() {
  const loading = useAppSelector(getLoading);
  const preferences = useAppSelector((state) => state.userPreferences.preferences);
  const currentUserId = useAppSelector((state) => state.user.userId);
  const userPreference = preferences.find((user) => user.userId === currentUserId);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const sessions = useAppSelector(getSessions);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "font-size",
      (userPreference?.appSize as string) || "14px"
    );
  }, [userPreference?.appSize]);

  useEffect(() => {
    const isAllowd = sessions ? sessions.session.token?.length > 0 : false;
    setIsLoggedIn(isAllowd);
  }, [sessions]);

  return (
    <AuthChecker>
      <div>
        <Routes>
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password-change" element={<PasswordChange />} />
          <Route path="/recover-password" element={<RecoverPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/create-business" element={<CreateBusiness />} />
          <Route path="/select-shop" element={<SelectShop />} />
          <Route path="" element={<DashboardWrapper />}>
            <Route path="dashboard" element={<Home />} />
            <Route path="sales" element={<Sales />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="subscriptions/details" element={<SubscriptionDetails />} />
            <Route path="manage-businesses" element={<ManageBusiness />} />
            <Route path="sales/new" element={<NewSales />} />
            <Route
              path="*"
              element={
                <>
                  <div style={{ width: "100%", height: "100%" }}>
                    <h1>Nothing matches your right</h1>
                  </div>
                </>
              }
            />
            <Route path="product" element={<Inventory />}>
              <Route index element={<ProductList />} />
              <Route path="batches" element={<Batches />} />
              <Route path="transfer" element={<Transfer />} />
            </Route>
            <Route path="suppliers" element={<Suppliers />} />
            <Route path="suppliers/single-supplier/:id" element={<SingleSupplierView />} />
            <Route path="product/add" element={<AddInventory />} />
            <Route path="shops/new" element={<CreateShop />} />
            <Route path="shops" element={<ShopPage />} />
            <Route path="expenses" element={<Expenses />} />
            <Route path="pos" element={<Pos />} />
            <Route path="stock-adjustment" element={<StockAdjustment />}>
              <Route index element={<AdjustStock />} />
              <Route path="history" element={<AdjustmentHistory />} />
              <Route path="stock-status" element={<AdjustmentStockStatus />} />
            </Route>
            <Route path="kitchen-orders" element={<OnlinePresence />}>
              <Route index element={<OrdersPage />} />
              <Route path="available-products" element={<Products />} />
              {/* <Route path="wallet" element={<WalletPage />} />
          <Route path="business-settings" element={<BusinessSettingsPage />} /> */}
            </Route>
            <Route path="staffs" element={<Staffs />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="invoices/new" element={<AddNewInvoice />} />
            <Route path="customers" element={<Customers />} />
            <Route path="customers/single-customer/:id" element={<SingleCustomerView />} />
            <Route path="report" element={<PageReport />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Routes>
        {loading && <Loader />}
        {/* {success && <Success />}
      {error && <ErrorModal />} */}
        <ToastContainer />
        <Snackbar timeout={6000} />
        <NoPermissionModal />
        <NoProductModal />
      </div>
    </AuthChecker>
  );
}

export default App;
